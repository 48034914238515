.dropdown_options_container input[type="radio"]:after {
  width: 16px;
  height: 16px;
  border-radius: 25px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--checkbox-primary);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 0 solid var(--white);
  cursor: pointer;
}

.dropdown_options_container input[type="radio"]:checked:after {
  background-image: url(https://storage.googleapis.com/dechea-microservices-files/patient-management/tickIcon.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--checkbox-secondary);
  content: "";
  display: inline-block;
  visibility: visible;

  cursor: pointer;
}
