.scrollbar {
  position: relative;
  ::-webkit-scrollbar {
    width: 4px;
    height: 50px;
  }
  ::-webkit-scrollbar-track {
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #7e98ba;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #7e98ba;
    border-radius: 2px;
  }
}
.circle {
  border-radius: 33px;
  height: 16px;
  width: 16px;
}

.optionWrapper {
  display: flex;
  align-items: center;
  margin-left: 0;
}

.label {
  margin-left: 16px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: inherit;
}

.defImage {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.valueContainer {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.valueLabel {
  margin-left: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #f3f8fe;
}

.birthDate {
  display: flex;
  justify-self: flex-end;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  text-align: right;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: inherit;
}

.profileContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropMenu {
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  cursor: pointer;
}

.icon {
  color: inherit;
}

.addPatientContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.seperator {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 16px;
  height: 30px;
  width: 2px;
  background-color: #e3ecf7;
}

.plusIcon {
  margin-left: 12px;
}

.selectDropdown {
  z-index: 4;
}
.placeHolderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.placeHolderText {
  color: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  font-feature-settings: 'tnum' on, 'lnum' on;
  margin-left: 8px;
}

.searchIcon {
  display: flex;
  position: absolute;
  left: 12px;
}

.crossIcon {
  cursor: pointer;
  padding-right: 8px;
  margin-bottom: 1px;
}

.dropMenuActive {
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid #346bd1;
  background-color: #193566 !important;
  border-collapse: collapse;
  color: #f3f8fe !important;

  .actionContainer {
    transform: rotate(180deg);
  }
}

.root {
  background-color: transparent;
  padding: 10px 12px;
  border-radius: 12px;
  color: #869dc5;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    color: #f3f8fe;
    background-color: #193566;
  }
  &:active {
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid #346bd1;
    border-collapse: collapse;
  }
}

.actionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #193566;
  height: 20px;
  width: 20px;
  border-radius: 6px;
  color: #f3f8fe;
}

.noOptionMessage {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  font-feature-settings: 'tnum' on, 'lnum' on;

  /* Grey 300 */

  color: #adbdd8;
}

.error {
  border: 1px solid #ff5573;

  box-sizing: border-box;
  border-radius: 12px;
  border-collapse: collapse;
  color: #f3f8fe !important;
}
