.dropdown_options_container input[type="checkbox"] {
  visibility: hidden;
}

.dropdown_options_container label {
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.dropdown_options_container input[type="checkbox"]+label:before {
  content: "\00a0";
  display: inline-block;
  height: 16px;
  width: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  background: #051A3E;
  border-radius: 4px;
  outline: none;
}

.dropdown_options_container input[type="checkbox"]:checked+label:before {
  padding-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #346BD1;
  color: var(--white);
  content: url(https://storage.googleapis.com/dechea-microservices-files/patient-management/tickIcon.svg);
  text-align: center;
  border: none;
}

.dropdown_options_container input[type="checkbox"]:checked+label:after {
  font-weight: bold;
}

.dropdown_options_container input[type="checkbox"]:focus+label::before {
  outline: none;
}