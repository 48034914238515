.mbsc-ios.mbsc-segmented {
  background-color: var(--selago);
}

/* <---------------today ui------------->> */
.mbsc-ios.mbsc-calendar-button.mbsc-button.cal-header-prev,
.mbsc-ios.mbsc-calendar-button.mbsc-button.cal-header-next {
  color: var(--primary);
  background-color: var(--selago);
  width: 24px;
  height: 24px;
  border-radius: 8px;
}

.mbsc-ios.mbsc-calendar-button.mbsc-button.cal-header-today {
  color: var(--rhino);
  background-color: var(--selago);
  height: 24px;
  border-radius: 8px;
  margin: 0 4px;
  font-size: 14px;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  /* padding: 2px 12px; */
  min-width: 66px;
}

.mbsc-button-icon.mbsc-ltr.mbsc-icon.mbsc-ios {
  width: 12px;
  height: 12px;
}

/* <----------------header background-------------> */
.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header,
.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-week-days {
  background-color: var(--white);
  color: var(--waikawaGray);
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  margin-top: 20px;
  margin-bottom: 50px;
}

.mbsc-ios.mbsc-eventcalendar-schedule .mbsc-calendar-day,
.mbsc-ios.mbsc-schedule-wrapper {
  background-color: var(--white);
  color: var(--waikawaGray);
  font-size: 14px;
  font-family: Montserrat, sans-serif;
}

/* <------------selected tab------------>>>> */
.mbsc-ios.mbsc-segmented-selectbox-inner.mbsc-selected {
  box-shadow: 0 1px 4px rgb(118 148 188 / 20%);
}

.md-switching-view-cont .mbsc-segmented {
  max-width: 202px !important;
}

.mbsc-ios.mbsc-segmented-button.mbsc-button {
  color: var(--glaucous);
  font-size: 12px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
}

.mbsc-ios.mbsc-segmented-button.mbsc-selected {
  color: var(--primary);
}

.mbsc-ios.mbsc-segmented-item:before {
  border-color: var(--linkWater);
}

/* <--------- calendar nav -----------> */
.cal-header-nav .mbsc-ios .mbsc-calendar-title.mbsc-calendar-month.mbsc-ios {
  color: var(--primary);
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
}

.mbsc-calendar-title.mbsc-calendar-year.mbsc-ios {
  color: var(--pigeonPost);
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
}

/* mbsc-calendar-button mbsc-calendar-button-today cal-header-today mbsc-reset mbsc-font mbsc-button mbsc-ios mbsc-ltr mbsc-button-flat mbsc-hover */