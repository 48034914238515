:root {
  --primary: #314363 !important;
  --marinerBlue: #346BD1 !important;
  --lightSolitudeGray: #E0E8F7 !important;
  --aeroBlue: #8eb2f4 !important;
  --bluetifulBlue: #3a79ee !important;
  --poloBlue: #869DC5 !important;
  --biscay: #193566 !important;
  --selago: #F3F8FE !important;
  --stTropaz: #2E5393 !important;
  --shipCove: #6f8bbc !important;
  --polar: #eff5fc !important;
  --pigeonPost: #a0b5d9 !important;
  --waikawaGray: #556E9A !important;
  --deepCove: #051A3E !important;
  --wedgewood: #5278AA !important;
  --wildWatermelon: #FF5573 !important;
  --shipCove2: #6C86B5 !important;
  --glaucous: #6c86b5 !important;
  --mayaBlue: #68D6FF !important;
  --bleuDeFrance: #307CE7 !important;
  --white: #FFFFFF;
  --rhino: #263857 !important;
  --linkWater: #D9E2F3 !important;
  --zumthor:#E3EDFF!important;
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Montserrat, sans-serif !important;
}