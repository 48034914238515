.md-switching-view-cont .mbsc-segmented {
  max-width: 300px;
  margin: 0 auto;
}

.cal-header-picker {
  flex: 1 0 auto;
}

.cal-header-nav {
  width: 200px;
}

/* material header order */

.mbsc-material.cal-header-prev {
  order: 1;
}

.mbsc-material.cal-header-next {
  order: 2;
}

.mbsc-material.cal-header-nav {
  order: 3;
}

.mbsc-material .cal-header-picker {
  order: 4;
}

.mbsc-material .cal-header-today {
  order: 5;
}

/* windows header order */

.mbsc-windows.cal-header-nav {
  order: 1;
}

.mbsc-windows.cal-header-prev {
  order: 2;
}

.mbsc-windows.cal-header-next {
  order: 3;
}

.mbsc-windows .cal-header-picker {
  order: 4;
}

.mbsc-windows .cal-header-today {
  order: 5;
}

/* to override event range dots in month view */

#mobEventCalendar {
  margin: 0 32px
}

#mobEventCalendar>.mbsc-calendar-label-resize:after {
  content: "";
  display: none;
  position: absolute;
  top: 48%;
  width: .8em;
  height: .8em;
  margin-top: -.4em;
  background: #fff;
  border-radius: .8em;
  border: 1px solid;
  box-sizing: border-box;
}

/* event label container in month view */

#mobEventCalendar>.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-text {
  height: 24px;
}

/* show more text and arrow */

#mobEventCalendar>.mbsc-calendar .mbsc-calendar-text.mbsc-ios.mbsc-ltr.mbsc-calendar-text-more .mbsc-calendar-label-text.mbsc-ios {
  padding: 4px 0 0 8px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: var(--shipCove2);
}

#mobEventCalendar>.mbsc-calendar .mbsc-calendar-text.mbsc-ios.mbsc-ltr.mbsc-calendar-text-more .mbsc-calendar-label-text.mbsc-ios::after {
  position: absolute;
  right: 0;
  content: url('https://storage.googleapis.com/dechea-microservices-files/calendar/darkDownArrow.svg');
}

#mobEventCalendar>.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
  min-height: 6.75em;
}

/* more events popup */

.showMoreEventsPopover .mbsc-ios.mbsc-popup-body-round {
  background: #FFFFFF;
  box-shadow: 0 8px 40px 12px rgb(9 60 138 / 8%);
  border-radius: 8px;
}

.showMoreEventsPopover .mbsc-ios.mbsc-popup-arrow {
  background: #FFFFFF;
  box-shadow: 0 4px 24px -8px rgba(9, 54, 138, 0.1);
}

.showMoreEventsPopover .mbsc-popover-list.mbsc-ltr.mbsc-font.mbsc-list.mbsc-ios {
  padding: 4px;
}

.showMoreEventsPopover .mbsc-ios.mbsc-list-item {
  padding: 0;
}

.showMoreEventsPopover .mbsc-ios.mbsc-list-item:after,
.mbsc-ios.mbsc-list-item:before {
  border: none;
}

.showMoreEventsPopover .mbsc-event.mbsc-list-item.mbsc-ios.mbsc-ltr.mbsc-list-item-actionable {
  display: block;
}

.showMoreEventsPopover .mbsc-popover-list {
  width: 191px;
}

.showMoreEventsPopover .mbsc-ios.mbsc-event.mbsc-list-item.mbsc-hover:before {
  background: none;
}

@media only screen and (min-width: 1300px) {
  .showMoreEventsPopover .mbsc-popover-list {
    width: 220px;
  }
}

@media only screen and (min-width: 1500px) {
  .showMoreEventsPopover .mbsc-popover-list {
    width: 240px;
  }
}

.mbsc-ios.mbsc-calendar-cell {
  border-color: #E3EDFF;
}