/* datepicker date font */

.mbsc-ios.mbsc-datepicker .mbsc-calendar,
.mbsc-ios.mbsc-datepicker .mbsc-calendar-cell,
.mbsc-ios.mbsc-datepicker .mbsc-calendar-slide {
  background-color: var(--biscay);
  color: var(--selago);
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  /* 
    line-height: 130%; */
}

/* selected date */

.mbsc-ios.mbsc-datepicker .mbsc-highlighted .mbsc-calendar-day-text,
.mbsc-ios.mbsc-datepicker .mbsc-selected .mbsc-calendar-day-text {
  background-color: var(--marinerBlue) !important;
  border-color: var(--marinerBlue) !important;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  /* font-size: 12px;
    line-height: 130%;
    border-radius: 12px; */
  /* color  */
}

/* week name */

.mbsc-datepicker .mbsc-calendar-week-days>.mbsc-ios.mbsc-calendar-week-day {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: var(--poloBlue);
}

/* date hover effect */

.mbsc-ios.mbsc-datepicker .mbsc-highlighted .mbsc-calendar-day-text,
.mbsc-ios.mbsc-datepicker .mbsc-hover .mbsc-calendar-day-text {
  background-color: var(--stTropaz);
}

/* datepicker nav header */
.mbsc-popup-content .mbsc-datepicker-tab-wrapper.mbsc-ios .mbsc-icon {
  width: 12px;
  height: 12px;
}

.mbsc-popup-content .mbsc-datepicker-tab-wrapper.mbsc-ios .mbsc-calendar-button.mbsc-reset.mbsc-font.mbsc-button.mbsc-ios.mbsc-ltr.mbsc-button-flat {
  color: var(--selago);
  font-size: 16px;
  font-weight: bold;
  font-family: Montserrat, sans-serif;
}

.mbsc-ios.mbsc-scroller-pointer.mbsc-scroller {
  background: var(--biscay);
}

.mbsc-ios.mbsc-scroller-wheel-item.mbsc-hover {
  background-color: var(--stTropaz);
  border-radius: 8px;
}

.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-item {
  color: var(--lightSolitudeGray);
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}