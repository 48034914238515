/* 
  1. dropdown options conatiner ( 11-19 )
  2. dropdown search container ( 22-72 )
  3. dropdown options container ( 76-131 )
  4. dropdown separator line ( 135-139 )
  5. dropdown scrollbar ( 141-160 )
  6. dropdown input field ( 162-260 )
*/

/* dropdown options conatiner */

.dropdown_select_container {
  z-index: 1;
  width: -webkit-fill-available;
  /* max-height: 395px !important; */
  background: var(--biscay);
  border-radius: 12px;
  padding: 8px;
  position: absolute;
  /* overflow-y: auto;
  overflow-x: hidden; */
  margin-top: 4px;
}

/* dropdown search container */

.search_container {
  /* width: 100%; */
  /* height: 48px; */
  background: var(--deepCove);
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: var(--selago);
  padding: 8px 12px;
  border: 1px solid transparent;
}

.search_icon {
  padding-right: 8px;
  color: var(--poloBlue);
}

.search_icon-inactive {
  color: var(--selago);
}

.searchWrapper {
  /* margin: 0 4px; */
}

.search_container>input {
  font-style: normal;
  font-weight: 500;
  color: inherit;
  font-size: 14PX;
  line-height: 140%;
  background-color: inherit;
  border: none;
  outline: none;
  flex: auto;
  width: 100%;
}

.search_container>input::placeholder {
  color: var(--poloBlue) !important;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  line-height: 140%;
  opacity: 1;
}

.search_container>input:-ms-input-placeholder {
  color: var(--shipCove) !important;
}

.search_container>input::-ms-input-placeholder {
  color: var(--shipCove) !important;
}

.search_container>span {
  font-weight: 500;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  text-align: center;
  vertical-align: middle;
  color: var(--polar);
  cursor: pointer;
  margin-right: 23px;
  margin-left: 10px;
}

.search_container_focus {
  background: var(var(--search-input-bgbase));
  border: 1px solid var(--checkbox-secondary);
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--search-input-color-focus);
  /* width: 280px; */
}

/* dropdown options container */

.dropdown_options_container {
  /* height: 56px; */
  display: flex;
  align-items: center;
  /* padding-right: 24px; */
  margin: 8px auto;
  padding: 8px 12px;
  border-radius: 8px;
  /* padding-left: 20px;
  padding-right: 16px; */
  cursor: pointer;
}

.dropdown_group_options_container {
  height: unset;
  margin: 8px auto;
  /* padding-top: 15px;
  padding-bottom: 15px; */
}

.dropdown_group_header_container {
  height: unset;
  padding-top: 12px;
  padding-bottom: 12px;
}

.dropdown_options_container:hover {
  background-color: var(--stTropaz);
}

/* dropdown groplist header */

.dropdown_options_container .header {
  font-weight: 500;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: var(--pigeonPost);
  margin: 0;
  width: calc(100% - 10px);
}

.dropdown_options_container .length_container {
  width: 24px;
  height: 24px;
  background: var(--grey-04);
  border: 2px solid var(--modal-black);
  border-radius: 33px;
  z-index: 99;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
}

.dropdown_options_container .length_container>span {
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  color: var(--white);
}

.dropdown_options_container>img {
  width: 24px;
  height: 24px;
  background: var(--grey-04);
  border: 2px solid var(--modal-black);
  border-radius: 33px;
  margin-left: -15px;
}

.dropdown_options_container>span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: var(--lightSolitudeGray);
  vertical-align: middle;
  margin-left: 8px;
  font-family: Montserrat, sans-serif !important;
}

/* dropdown separator line */

.separator {
  opacity: 0.5;
  border: 1px solid var(--secondory);
}

/* dropdown scrollbar */

.option_container {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 220px;
  text-overflow: ellipsis;
}

.option_container::-webkit-scrollbar {
  width: 2px;
  border-radius: 9px;
}

.option_container::-webkit-scrollbar-thumb {
  background: var(--waikawaGray);
}

.option_container::-webkit-scrollbar-track {
  background-color: var(--deepCove);
}

/* dropdown input field */

.dropdown_input_container {
  background: transparent;
  border-radius: 12px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  color: var(--poloBlue);
  padding: 10px 12px;
  border: 1px solid transparent;
}

.dropdown_input_container:hover {
  background: var(--biscay);
  color: var(--selago);
}

.dropdown_input_container_enabled {
  background: var(--biscay);
  border: 1px solid var(--marinerBlue);
  box-sizing: border-box;
  border-radius: 12px;
  color: var(--lightSolitudeGray);
}

.validationError {
  border: 1px solid var(--wildWatermelon);
  border-radius: 12px;
  color: var(--lightSolitudeGray);
}

/* .dropdown_input_container_enabled:hover {
  background: var(--white);
  border: 1px solid #ebf0f6;
  box-sizing: border-box;
  border-radius: 16px;
  color: var(--dark-blue);
} */

.dropdown_input_container .img_container {
  display: flex;
}

.dropdown_input_container .img_container .total_team_text {
  /* width: 32px;
  height: 32px; */
  /* background: var(--dark-blue); */
  background: var(--wedgewood);
  /* border: 3px solid var(--white-primary); */
  border: 2px solid var(--deepCove);
  border-radius: 50px;
  z-index: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
}

.dropdown_input_container .img_container .total_team_text-active {
  background: var(--marinerBlue);
}

.dropdown_input_container .img_container .total_team_text>span {
  font-weight: 600;
  font-size: 10px;
  /* line-height: 120%; */
  text-align: center;
  color: var(--selago);
  /* vertical-align: sub; */
}

.dropdown_input_container .img_container>img {
  width: 20px;
  height: 20px;
  border: 3px solid var(--white-primary);
  border-radius: 50px;
}

.dropdown_input_container .img_container>img:nth-last-child(2) {
  z-index: 1;
  margin-left: -23px;
}

.dropdown_input_container .img_container>img:nth-last-child(1) {
  margin-left: -20px;
}

.dropdown_input_container .middle_section {
  display: flex;
  align-items: center;
  margin-left: 8px;
  text-align: left;
}

.dropdown_input_container .middle_section>span {
  vertical-align: -webkit-baseline-middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: inherit;
  text-overflow: ellipsis;
  max-width: 240px;
  white-space: nowrap;
  display: inline;
  overflow: hidden;
}

.right_section {
  display: flex;
  align-self: center;
}

.displayFlex {
  display: flex;
  align-items: center;
}

.default-image {
  z-index: 1;
  margin-left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.default-image-group-1 {
  width: 20px;
  height: 20px;
  border: 2px solid var(--deepCove);
  border-radius: 50px;
  z-index: 1;
  margin-left: -12px;
}

.single-default-image-group-1 {
  margin-left: 0;
}

.default-image-group-2 {
  width: 20px;
  height: 20px;
  border: 3px solid var(--deepCove);
  border-radius: 50px;
  margin-left: -12px;
}

.default-image-all-options-1 {
  z-index: 1;
  width: 24px;
  height: 24px;
  background: var(--grey-04);
  border: 2px solid var(--modal-black);
  border-radius: 33px;
  margin-left: -15px;
}

.default-image-all-options-2 {
  width: 24px;
  height: 24px;
  background: var(--grey-04);
  border: 2px solid var(--modal-black);
  border-radius: 33px;
  margin-left: -15px;
}

.action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--biscay);
  height: 20px;
  width: 20px;
  border-radius: 6px;
  color: var(--selago);
}

.dropdown_input_container_enabled .action-container {
  transform: rotate(180deg);
}

.selected-emp .middle_section>span {
  color: var(--selago);
}

.dropDownRoot {
  width: 100%;
  position: relative
}