/* popup anchor arrow */

.eventPopup .mbsc-ios.mbsc-popup-arrow {
  background: #051A3E !important;
  box-shadow: 0 8px 32px rgb(174 192 225 / 20%) !important;
}

/* popup box shadow and radius */

.eventPopup .mbsc-ios.mbsc-popup-body-round {
  box-shadow: 0 8px 32px rgb(174 192 225 / 20%) !important;
  border-radius: 16px !important;
}

/* popup body */

.eventPopup .mbsc-ios.mbsc-popup-body {
  background: #051A3E !important;
  color: #ffffff !important;
}

/* width */

.eventPopup .mbsc-popup ::-webkit-scrollbar {
  width: 2px;
  border-radius: 9px;
}

/* Track */

.eventPopup .mbsc-popup ::-webkit-scrollbar-track {
  background: #051A3E;
}

/* Handle */

.eventPopup .mbsc-popup ::-webkit-scrollbar-thumb {
  background: #556E9A;
}

/* Handle on hover */

.eventPopup .mbsc-popup ::-webkit-scrollbar-thumb:hover {
  background: #556E9A;
}